<template>
  <div>
    <personnel_bar/> 
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลใบอนุญาตประกอบวิชาชีพ"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="8" class="text-right">
              <v-row no-gutters>
                <v-col cols="12" class="pa-2">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                    single-line
                    hide-details
                    v-on:keyup.enter="OnEnter()"
                    filled
                    class="mb-2"
                  />
                  <v-radio-group row>
                    <v-radio
                      label="ชื่อ"
                      value="radio-1"
                      @click.native="search_name()"
                    ></v-radio>
                    <v-radio
                      label="นามสกุล"
                      value="radio-2"
                      @click.native="search_lastname()"
                    ></v-radio>
                    <v-radio
                      label="รหัสบัตรประชาชน"
                      value="radio-3"
                      @click.native="search_idcard()"
                    ></v-radio>

                    <v-radio
                      label="ปีที่เกษียณ"
                      value="radio-5"
                      @click.native="search_yearretire()"
                    ></v-radio>
                    <v-radio
                      label="แสดงทั้งหมด"
                      value="radio-6"
                      @click.native="personnel_temporaryQueryAll()"
                    ></v-radio>                  
                    <v-radio
                      label="ใบกอบวิชาชีพผู้บริหารสถานศึกษา"
                      value="radio-7"
                      @click.native="personnel_temporaryLicenseDirector()"
                    ></v-radio>


                    <v-radio
                      label="ใบกอบวิชาชีพผู้บริหารสถานศึกษา (ข้าราชการครู)"
                      value="radio-7"
                      @click.native="personnel_temporaryLicenseDirectorTeach()"
                    ></v-radio>


                  </v-radio-group>
                </v-col>

                <v-col cols="4" class="pa-2">
                  <v-select
                    v-model="rangname_search"
                    outlined
                    :items="rang_names"
                    item-text="text"
                    item-value="value"
                    label="วิทยฐานะ"
                    @change="search_rang()"
                  >
                  </v-select>
                </v-col>
                <v-col cols="4" class="pa-2">
                  <v-select
                    v-model="position_search"
                    outlined
                    :items="position_select"
                    item-text="text"
                    item-value="value"
                    label="ตำแหน่ง"
                    @change="search_position()"
                  >
                  </v-select>
                </v-col>

                <v-col cols="4" class="pa-2">
                  <v-autocomplete
                    v-model="college_search"
                    outlined
                    :items="colleges"
                    item-text="college_name"
                    item-value="college_code"
                    label="สถานศึกษา"
                    @change="search_college()"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" class="text-right pa-2" >
              <v-btn block color="info" large @click="clearSearch()">
                <v-icon>mdi-format-clear</v-icon>
                Clear Search
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="personnel_temporarys"
          class="elevation-1"
          :loading="loading"
          :search="search"
        >
        <template v-slot:item.pt_licencedr_type="{item}">
          <div v-if="item.pt_licencedr_type==='a'">
            สำเร็จการศึกษา
          </div>
          <div v-else-if="item.pt_licencedr_type==='b'">
            การเทียบประสบการณ์
          </div>
        </template>
        <template v-slot:item.pt_ondate="{ item }">
            {{
              item.pt_ondate
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.pt_enddate="{ item }">
            {{
              item.pt_enddate
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>
        <template v-slot:item.Agelicence="{ item }">
            <div v-if="!item.Agelicence">
              <v-chip color="warning" dark>
                <v-icon>mdi-information-outline</v-icon>
                <span class="font-weight-bold"> </span>
              </v-chip>
            </div>

            <div v-else-if="item.Agelicence <= 60">
              <v-chip color="red" dark>
                <span class="font-weight-bold">
                  {{ item.Agelicence || "" }}
                </span>
              </v-chip>
            </div>
            <div v-else-if="item.Agelicence <= 180">
              <v-chip color="warning" dark>
                <span class="font-weight-bold">
                  {{ item.Agelicence || "" }}
                </span>
              </v-chip>
            </div>

            <div v-else>
              <span class="font-weight-bold green--text">
                {{ item.Agelicence || "" }}
              </span>
            </div>
          </template>


          <template v-slot:item.brith_day="{ item }">
            {{
              item.brith_day + "/" + item.brith_month + "/" + item.brith_year
            }}
          </template>
          <template v-slot:item.appoin_day="{ item }">
            {{
              item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year
            }}
          </template>

          <template v-slot:item.date_app_now="{ item }">
            {{
              item.date_app_now
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.date_app_positonnow="{ item }">
            {{
              item.date_app_positonnow
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.Login="{ item }">
            <v-icon color="info" @click="userLogin(item.id_card, item.p_word)">
              mdi-puzzle
            </v-icon>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="warning"
              @click.stop="personnel_temporaryEdit(item.id_card)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.dels="{ item }">
            <v-icon
              color="red"
              @click.stop="personnel_temporaryDelete(item.id_card)"
            >
              mdi-delete
            </v-icon>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>

      <!--addpersonnel_temporarydialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addpersonnel_temporarydialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลข้าราชการครูและบุคลากรทางการศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addpersonnel_temporaryform" lazy-validation>
                <v-container grid-list-md>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <span class="font-weight-bold"
                        >1. ประเภทบุคคล/สังกัด</span
                      >
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        rounded
                        filled
                        v-model="addpersonnel_temporary.user_status"
                        :items="userstatus"
                        item-text="user_status_name"
                        item-value="user_status_sub"
                        label="Type"
                        required
                        :rules="[v => !!v || '']"
                        prepend-icon="mdi-format-list-bulleted-type"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.college_code"
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        label="วิทยาลัย"
                        @change="man_powerQuery()"
                        required
                        :rules="[v => !!v || '']"
                        prepend-icon="mdi-school"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >2. ข้อมูลพื้นฐาน</span
                      ></v-col
                    >

                    <v-col cols="12" md="3">
                      <v-text-field
                        @input="validateInputAdd()"
                        prepend-icon="mdi-account-key"
                        rounded
                        outlined
                        label="รหัสบัตรประชาชน"
                        v-model="addpersonnel_temporary.id_card"
                        :rules="[
                          v => (!!v && v.length == 13) || '13  ID Card',
                          validateInputcheck === 'true' ||
                            'กำหนดรหัสบัตรประชาชนไม่ถูกต้อง'
                        ]"
                        maxlength="13"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-autocomplete
                        :items="prefixs"
                        item-text="prefix_name"
                        item-value="prefix_name"
                        prepend-icon="mdi-account-box"
                        rounded
                        outlined
                        label="คำนำหน้าชื่อ"
                        v-model="addpersonnel_temporary.title_s"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-account-box"
                        rounded
                        outlined
                        label="ชื่อ"
                        v-model="addpersonnel_temporary.frist_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        prepend-icon="mdi-account-box"
                        rounded
                        outlined
                        label="นามสกุล"
                        v-model="addpersonnel_temporary.last_name"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-select
                        :items="days"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.brith_day"
                        label="วันที่เกิด : 8"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-select
                        :items="mounts"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.brith_month"
                        label="เดือนเกิด : 8"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="years"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.brith_year"
                        label="ปีเกิด : 2531"
                        type="number"
                        maxlength="4"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-cellphone"
                        rounded
                        outlined
                        label="เบอร์โทร"
                        v-model="addpersonnel_temporary.tel_p"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-email"
                        rounded
                        outlined
                        label="E-mail"
                        v-model="addpersonnel_temporary.e_mail"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold">3. ตำแหน่ง</span></v-col
                    >

                    <v-col cols="12" md="4">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.position_name"
                        :items="position_select"
                        item-text="text"
                        item-value="text"
                        label="ตำแหน่ง"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.user_status"
                        :items="userstatus"
                        item-text="user_status_name"
                        item-value="user_status_sub"
                        label="สถานะ"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.id_position"
                        :items="man_powers"
                        item-text="id_position"
                        item-value="id_position"
                        label="เลขที่ตำแหน่ง"
                        ><template v-slot:item="{ item }">
                          <div>
                            <div class="font-weight-bold">
                              {{ item.id_position }}
                            </div>
                            <div>{{ item.positions }} : {{ item.id_card }}</div>
                          </div>
                        </template></v-autocomplete
                      >
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        outlined
                        rounded
                        :items="rang_names"
                        item-text="text"
                        item-value="value"
                        label="วิทยฐานะ"
                        v-model="addpersonnel_temporary.rang_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-select
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.rang_level"
                        :items="rang_levels"
                        item-text="text"
                        item-value="value"
                        label="ระดับ"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >4. วุฒิการศึกษา</span
                      ></v-col
                    >

                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        label="คุณวิฒิ"
                        v-model="addpersonnel_temporary.ed_abb"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        label="สาขาวิชา"
                        v-model="addpersonnel_temporary.ed_name"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold">5. การบรรจุ</span></v-col
                    >

                    <v-col cols="12" md="4">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.type_personnel"
                        :items="type_personnelApp"
                        item-text="text"
                        item-value="value"
                        label="ประเภทการบรรจุ"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-5">
                      <v-checkbox
                        v-model="addpersonnel_temporary.rang_condition"
                        value="1"
                        hide-details
                        class="shrink mr-2 mt-0"
                        label="กลุ่ม จชต. ชายแดนภาคใต้"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-content-duplicate"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.order_app_now"
                        label="คำสั่งที่บรรจุ"
                      ></v-text-field>
                    </v-col>

                    <!-- บรรจะ -->
                    <v-col cols="12" md="4">
                      <v-select
                        :items="days"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar-clock"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.appoin_day"
                        label="วันที่บรรจุ"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-select
                        :items="mounts"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar-clock"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.appoin_month"
                        label="เดือนบรรจุ"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="years"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar-clock"
                        rounded
                        outlined
                        v-model="addpersonnel_temporary.appoin_year"
                        label="ปีบรรจุ"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >6. การปฏิบัติงาน ณ ปัจจุบัน</span
                      ></v-col
                    >

                    <v-col cols="12" md="6">
                      <v-menu
                        ref="menu5"
                        v-model="menu5"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-icon="mdi-calendar-clock"
                            rounded
                            outlined
                            v-model="addpersonnel_temporary.date_app_now"
                            label="วันเดือนปีที่เริ่มปฏิบัติงาน ณ สถานศึกษาปัจจุบัน"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addpersonnel_temporary.date_app_now"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu5 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu5.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-menu
                        ref="menu6"
                        v-model="menu6"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-icon="mdi-calendar-clock"
                            rounded
                            outlined
                            v-model="addpersonnel_temporary.date_app_positonnow"
                            label="วันเดือนปีที่เริ่มปฏิบัติงาน ใน ตำแหน่งปัจจุบัน"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addpersonnel_temporary.date_app_positonnow"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu6 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu6.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
                <small>* จำเป็น</small>
                <span>ปีเกษียณ :{{ retrire_year }}</span>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addpersonnel_temporarydialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addpersonnel_temporarySubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model csvpersonnel_temporarydialog -->
      <v-layout>
        <v-dialog
          v-model="csvpersonnel_temporarydialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลไฟล์ CSV"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form
                    ref="csvpersonnel_temporarydialogform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            block
                            large
                            color="info"
                            to="/college/personnel_form_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกไฟล์บันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-file-input
                            v-model="file2"
                            accept=".csv"
                            name="csvFiles"
                            color="deep-purple accent-4"
                            counter
                            label="ไฟล์ .csv"
                            placeholder="ไฟล์ CSV ข้อมูลบุคคล สอศ"
                            prepend-icon="mdi-paperclip"
                            outlined
                            rounded
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                          <v-btn
                            block
                            large
                            color="green"
                            @click.stop="csvpersonnel_temporarySubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="csvpersonnel_temporarydialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model csvpersonnel_temporaryOutofdialog -->
      <v-layout>
        <v-dialog
          v-model="csvpersonnel_temporaryOutofdialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลไฟล์ ผู้ออกจากราชการ ลาออก เกษียณ ถึงแก่กรรม CSV"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form
                    ref="csvpersonnel_temporaryOutofdialogform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            block
                            large
                            color="info"
                            to="/admin/personneloutof_form_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกไฟล์บันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-file-input
                            v-model="file2"
                            accept=".csv"
                            name="csvFiles"
                            color="deep-purple accent-4"
                            counter
                            label="ไฟล์ .csv"
                            placeholder="ไฟล์ CSV ข้อมูลบุคคล สอศ"
                            prepend-icon="mdi-paperclip"
                            outlined
                            rounded
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                          <v-btn
                            block
                            large
                            color="green"
                            @click.stop="csvpersonnel_temporaryOutofSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="csvpersonnel_temporaryOutofdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletepersonnel_temporarydialog -->
      <v-layout>
        <v-dialog
          v-model="deletepersonnel_temporarydialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลผู้ใช้"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deletepersonnel_temporaryform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูลผู้ใช้
                          {{ editpersonnel_temporary.college_code }}
                          <span v-if="editpersonnel_temporary.college_name">{{
                            editpersonnel_temporary.college_name
                          }}</span>
                          <div>
                            {{ editpersonnel_temporary.title_s
                            }}{{ editpersonnel_temporary.frist_name }}
                            {{ editpersonnel_temporary.last_name }}
                          </div>
                          <div>
                            {{ editpersonnel_temporary.position_name }} :
                            {{ editpersonnel_temporary.id_position }}
                          </div>
                        </v-flex>
                        <v-text-field
                          filled
                          rounded
                          type="password"
                          label="Password"
                          :rules="[v => v === '0612698358']"
                        >
                        </v-text-field>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletepersonnel_temporarydialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletepersonnel_temporarySubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editpersonnel_temporarydialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editpersonnel_temporarydialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_temporaryform" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" md="12">
                    <span class="font-weight-bold">1. ประเภทบุคคล/สังกัด</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      rounded
                      filled
                      v-model="editpersonnel_temporary.user_status"
                      :items="userstatus"
                      item-text="user_status_name"
                      item-value="user_status_sub"
                      label="Type"
                      required
                      :rules="[v => !!v || '']"
                      prepend-icon="mdi-format-list-bulleted-type"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.college_code"
                      :items="colleges"
                      item-text="college_name"
                      item-value="college_code"
                      label="วิทยาลัย"
                      @change="man_powerQuery()"
                      required
                      :rules="[v => !!v || '']"
                      prepend-icon="mdi-school"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold"
                      >2. ข้อมูลพื้นฐาน</span
                    ></v-col
                  >

                  <v-col cols="12" md="3">
                    <v-text-field
                      @input="validateInput()"
                      prepend-icon="mdi-account-key"
                      rounded
                      outlined
                      label="รหัสบัตรประชาชน"
                      v-model="editpersonnel_temporary.id_card"
                      :rules="[
                        v => (!!v && v.length == 13) || '13  ID Card',
                        validateInputcheck === 'true' ||
                          'กำหนดรหัสบัตรประชาชนไม่ถูกต้อง'
                      ]"
                      maxlength="13"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      :items="prefixs"
                      item-text="prefix_name"
                      item-value="prefix_name"
                      prepend-icon="mdi-account-box"
                      rounded
                      outlined
                      label="คำนำหน้าชื่อ"
                      v-model="editpersonnel_temporary.title_s"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="mdi-account-box"
                      rounded
                      outlined
                      label="ชื่อ"
                      v-model="editpersonnel_temporary.frist_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      prepend-icon="mdi-account-box"
                      rounded
                      outlined
                      label="นามสกุล"
                      v-model="editpersonnel_temporary.last_name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      :items="days"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.brith_day"
                      label="วันที่เกิด : 8"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      :items="mounts"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.brith_month"
                      label="เดือนเกิด : 8"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      :items="years"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.brith_year"
                      label="ปีเกิด : 2531"
                      type="number"
                      maxlength="4"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-cellphone"
                      rounded
                      outlined
                      label="เบอร์โทร"
                      v-model="editpersonnel_temporary.tel_p"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-email"
                      rounded
                      outlined
                      label="E-mail"
                      v-model="editpersonnel_temporary.e_mail"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold">3. ตำแหน่ง</span></v-col
                  >

                  <v-col cols="12" md="4">
                    <v-autocomplete
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.position_name"
                      :items="position_select"
                      item-text="text"
                      item-value="text"
                      label="ตำแหน่ง"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-autocomplete
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.user_status"
                      :items="userstatus"
                      item-text="user_status_name"
                      item-value="user_status_sub"
                      label="สถานะ"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.id_position"
                      :items="man_powers"
                      item-text="id_position"
                      item-value="id_position"
                      label="เลขที่ตำแหน่ง"
                      ><template v-slot:item="{ item }">
                        <div>
                          <div class="font-weight-bold">
                            {{ item.id_position }}
                          </div>
                          <div>{{ item.positions }} : {{ item.id_card }}</div>
                        </div>
                      </template></v-autocomplete
                    >
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-settings"
                      outlined
                      rounded
                      :items="rang_names"
                      item-text="text"
                      item-value="value"
                      label="วิทยฐานะ"
                      v-model="editpersonnel_temporary.rang_name"
                      required
                      :rules="[v => !!v || '']"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.rang_level"
                      :items="rang_levels"
                      item-text="text"
                      item-value="value"
                      label="ระดับ"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold"
                      >4. วุฒิการศึกษา</span
                    ></v-col
                  >

                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      label="คุณวิฒิ"
                      v-model="editpersonnel_temporary.ed_abb"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      label="สาขาวิชา"
                      v-model="editpersonnel_temporary.ed_name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold">5. การบรรจุ</span></v-col
                  >

                  <v-col cols="12" md="4">
                    <v-autocomplete
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.type_personnel"
                      :items="type_personnelApp"
                      item-text="text"
                      item-value="value"
                      label="ประเภทการบรรจุ"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="4" class="pa-5">
                    <v-checkbox
                      v-model="editpersonnel_temporary.rang_condition"
                      value="1"
                      hide-details
                      class="shrink mr-2 mt-0"
                      label="กลุ่ม จชต. ชายแดนภาคใต้"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="mdi-content-duplicate"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.order_app_now"
                      label="คำสั่งที่บรรจุ"
                    ></v-text-field>
                  </v-col>

                  <!-- บรรจะ -->
                  <v-col cols="12" md="4">
                    <v-select
                      :items="days"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar-clock"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.appoin_day"
                      label="วันที่บรรจุ"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      :items="mounts"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar-clock"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.appoin_month"
                      label="เดือนบรรจุ"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      :items="years"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar-clock"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.appoin_year"
                      label="ปีบรรจุ"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold"
                      >6. การปฏิบัติงาน ณ ปัจจุบัน</span
                    ></v-col
                  >

                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menu5"
                      v-model="menu5"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          prepend-icon="mdi-calendar-clock"
                          rounded
                          outlined
                          v-model="editpersonnel_temporary.date_app_now"
                          label="วันเดือนปีที่เริ่มปฏิบัติงาน ณ สถานศึกษาปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_app_now"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu5 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu5.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menu6"
                      v-model="menu6"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          prepend-icon="mdi-calendar-clock"
                          rounded
                          outlined
                          v-model="editpersonnel_temporary.date_app_positonnow"
                          label="วันเดือนปีที่เริ่มปฏิบัติงาน ใน ตำแหน่งปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_app_positonnow"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu6 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu6.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editpersonnel_temporarydialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editpersonnel_temporarySubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import personnel_bar from "../../components/admin/personnel_bar.vue";

export default {
  components: { personnel_bar },
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      addpersonnel_temporarydialog: false,
      editpersonnel_temporarydialog: false,
      deletepersonnel_temporarydialog: false,
      csvpersonnel_temporarydialog: false,
      csvpersonnel_temporaryOutofdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      personnel_temporarys: [],
      addpersonnel_temporary: {},
      editpersonnel_temporary: {},
      search: "",
      pagination: {},
      headers: [
      {
          text: "Login",
          align: "center",
          value: "Login",
          icon: "mdi-puzzle"
        },

        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "dels",
          icon: "mdi-file-document-edit"
        },
        {
          text: "วิทยาลัย",        
          align: "left",
          value: "college_name"
        },
        { text: "รหัสบัตรประชาชน", align: "center", value: "id_card" },
        { text: "ชื่อ-นามสกุล", align: "center", value: "frist_names" },      
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        { text: "วิทยะฐานะ", align: "center", value: "rang_name" },
        { text: "บัตรหมดอายุ/วัน", align: "center", value: "Agelicence" },

        { text: "เลขที่", align: "center", value: "pt_licence" },       
        { text: "วันออกบัตร", align: "center", value: "pt_ondate" },       
        { text: "หมดอายุ", align: "center", value: "pt_enddate" },       
        { text: "ที่มาใบอนุญาต ผอ.", align: "center", value: "pt_licencedr_type" },       
      ],
      rang_names: [
        { text: "-", value: "-" },
        { text: "ต้น", value: "ต้น" },
        { text: "ปฏิบัติงาน", value: "ปฏิบัติงาน" },
        { text: "ปฏิบัติการ", value: "ปฏิบัติการ" },
        { text: "ชำนาญงาน", value: "ชำนาญงาน" },
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],
      position_select: [
        { text: "ผู้อำนวยการ", value: "ผู้อำนวยการ" },
        { text: "รองผู้อำนวยการ", value: "รองผู้อำนวยการ" },
        { text: "ครู", value: "ครู" },
        { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
        { text: "ศึกษานิเทศก์", value: "ศึกษานิเทศก์" },
        { text: "เจ้าพนักงานธุรการ", value: "เจ้าพนักงานธุรการ" },
        {
          text: "เจ้าพนักงานการเงินและบัญชี",
          value: "เจ้าพนักงานการเงินและบัญชี"
        },
        { text: "นักวิเคราะห์นโยบายและแผน", value: "นักวิเคราะห์นโยบายและแผน" },
        { text: "เจ้าพนักงานพัสดุ", value: "เจ้าพนักงานพัสดุ" }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      provinces: [],
      prefectures: [],
      userstatus: [],
      man_powers: [],
      colleges: [],
      personnel_temporarystatus: [],
      regions: [],
      region_ena: true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      data_syslog: {},
      college_search: {},
      position_search: {},
      rangname_search: {},
      upcsv_file: {},
      file2: null,
      type_personnelApp: [
        { text: "ครูพัฒนาท้องถิ่น", value: "local_dev" },
        { text: "ครูโอนย้าย", value: "transfer_position" },
        { text: "คัดเลือก(ภายใน)", value: "select" },
        { text: "แข่งขัน(ภายนอก)", value: "recruit" }
      ],
      rang_names: [
        { text: "-", value: "-" },
        { text: "ต้น", value: "ต้น" },
        { text: "ปฏิบัติงาน", value: "ปฏิบัติงาน" },
        { text: "ปฏิบัติการ", value: "ปฏิบัติการ" },
        { text: "ชำนาญงาน", value: "ชำนาญงาน" },
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],

      rang_levels: [
        { text: "-", value: "-" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" }
      ],
      validateInputcheck: [],
      days: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
        { text: "13", value: "13" },
        { text: "14", value: "14" },
        { text: "15", value: "15" },
        { text: "16", value: "16" },
        { text: "17", value: "17" },
        { text: "18", value: "18" },
        { text: "19", value: "19" },
        { text: "20", value: "20" },
        { text: "21", value: "21" },
        { text: "22", value: "22" },
        { text: "23", value: "23" },
        { text: "24", value: "24" },
        { text: "25", value: "25" },
        { text: "26", value: "26" },
        { text: "27", value: "27" },
        { text: "28", value: "28" },
        { text: "29", value: "29" },
        { text: "30", value: "30" },
        { text: "31", value: "31" }
      ],
      mounts: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" }
      ],
      years: [
        { text: "2500", value: "2500" },
        { text: "2501", value: "2501" },
        { text: "2502", value: "2502" },
        { text: "2503", value: "2503" },
        { text: "2504", value: "2504" },
        { text: "2505", value: "2505" },
        { text: "2506", value: "2506" },
        { text: "2507", value: "2507" },
        { text: "2508", value: "2508" },
        { text: "2509", value: "2509" },
        { text: "2510", value: "2510" },
        { text: "2511", value: "2511" },
        { text: "2512", value: "2512" },
        { text: "2513", value: "2513" },
        { text: "2514", value: "2514" },
        { text: "2515", value: "2515" },
        { text: "2516", value: "2516" },
        { text: "2517", value: "2517" },
        { text: "2518", value: "2518" },
        { text: "2519", value: "2519" },
        { text: "2520", value: "2520" },
        { text: "2521", value: "2521" },
        { text: "2522", value: "2522" },
        { text: "2523", value: "2523" },
        { text: "2524", value: "2524" },
        { text: "2525", value: "2525" },
        { text: "2526", value: "2526" },
        { text: "2527", value: "2527" },
        { text: "2528", value: "2528" },
        { text: "2529", value: "2529" },
        { text: "2530", value: "2530" },
        { text: "2531", value: "2531" },
        { text: "2532", value: "2532" },
        { text: "2533", value: "2533" },
        { text: "2534", value: "2534" },
        { text: "2535", value: "2535" },
        { text: "2536", value: "2536" },
        { text: "2537", value: "2537" },
        { text: "2538", value: "2538" },
        { text: "2539", value: "2539" },
        { text: "2540", value: "2540" },
        { text: "2541", value: "2541" },
        { text: "2542", value: "2542" },
        { text: "2543", value: "2543" },
        { text: "2544", value: "2544" },
        { text: "2545", value: "2545" },
        { text: "2546", value: "2546" },
        { text: "2547", value: "2547" },
        { text: "2548", value: "2548" },
        { text: "2549", value: "2549" },
        { text: "2550", value: "2550" },
        { text: "2551", value: "2551" },
        { text: "2552", value: "2552" },
        { text: "2553", value: "2553" },
        { text: "2554", value: "2554" },
        { text: "2555", value: "2555" },
        { text: "2556", value: "2556" },
        { text: "2557", value: "2557" },
        { text: "2558", value: "2558" },
        { text: "2559", value: "2559" },
        { text: "2560", value: "2560" }
      ],
      prefixs: [],
      personnel_tems:[],
    };
  },

  async mounted() {
    /* await this.personnel_temporaryQueryAll(); */
    await this.sweetAlertLoading();
    let result;
    result = await this.$http.post("collegetype.php", {
      ApiKey: this.ApiKey
    });
    this.collegetypes = result.data;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;
    result = await this.$http.post("province.php", {
      ApiKey: this.ApiKey
    });
    this.provinces = result.data;

    result = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result.data;

    let user_status_result;
    user_status_result = await this.$http.post("user_status.php", {
      ApiKey: this.ApiKey
    });
    this.userstatus = user_status_result.data;

    let prefix = await this.$http.post("prefix.php", { ApiKey: this.ApiKey });
    this.prefixs = prefix.data;

    Swal.close();
  },

  methods: {
    async personnel_temporaryLicenseDirectorTeach(){
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          pt_licencedr:"OK"
        })
        .finally(() => (this.loading = false));
    this.personnel_tems = result.data;
    let data = this.personnel_tems
         let obj = {};
      let arr = [];

      data.forEach(value => {
        if (value.pt_licencedr && value.pt_licencedr_file && value.user_status=='teacher') {
          obj["college_name"] = value.college_name;
          obj["id_card"] = value.id_card;
          obj["p_word"] = value.p_word;
          obj["frist_names"] = value.frist_names;
          obj["position_name"] = value.position_name; 
          obj["id_position"] = value.id_position;
          obj["rang_name"] = value.rang_name;
          
          obj["Agelicence"] = value.Agelicencedr;
          obj["pt_licence"] = value.pt_licencedr;
          obj["pt_ondate"] = value.pt_ondatedr;  
          obj["pt_enddate"] =  value.pt_enddatedr;
          obj["pt_licencedr_type"] =  value.pt_licencedr_type;
       
          arr.push({
            college_name: obj["college_name"],
            id_card: obj["id_card"],
            p_word: obj["p_word"],
            frist_names: obj["frist_names"],
            position_name: obj["position_name"],      
            id_position: obj["id_position"],
            rang_name: obj["rang_name"],
            Agelicence: obj["Agelicence"],
            pt_licence: obj["pt_licence"],
            pt_ondate: obj["pt_ondate"],
            pt_enddate: obj["pt_enddate"],           
            pt_licencedr_type: obj["pt_licencedr_type"],           
          });
        }
      });
      this.personnel_temporarys = arr; 
    },
    async personnel_temporaryLicenseDirector(){
      
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          pt_licencedr:"OK"
        })
        .finally(() => (this.loading = false));
    this.personnel_tems = result.data;
    let data = this.personnel_tems
         let obj = {};
      let arr = [];

      data.forEach(value => {
        if (value.pt_licencedr && value.pt_licencedr_file) {
          obj["college_name"] = value.college_name;
          obj["id_card"] = value.id_card;
          obj["p_word"] = value.p_word;
          obj["frist_names"] = value.frist_names;
          obj["position_name"] = value.position_name; 
          obj["id_position"] = value.id_position;
          obj["rang_name"] = value.rang_name;
          
          obj["Agelicence"] = value.Agelicencedr;
          obj["pt_licence"] = value.pt_licencedr;
          obj["pt_ondate"] = value.pt_ondatedr;  
          obj["pt_enddate"] =  value.pt_enddatedr;
          obj["pt_licencedr_type"] =  value.pt_licencedr_type;
       
          arr.push({
            college_name: obj["college_name"],
            id_card: obj["id_card"],
            p_word: obj["p_word"],
            frist_names: obj["frist_names"],
            position_name: obj["position_name"],      
            id_position: obj["id_position"],
            rang_name: obj["rang_name"],
            Agelicence: obj["Agelicence"],
            pt_licence: obj["pt_licence"],
            pt_ondate: obj["pt_ondate"],
            pt_enddate: obj["pt_enddate"],           
            pt_licencedr_type: obj["pt_licencedr_type"],           
          });
        }
      });
      this.personnel_temporarys = arr; 
    },

    async clearSearch() {
      this.college_search = "";
      this.position_search = "";
      this.rangname_search = "";
    },

    async userLogin(id_card, p_word) {
      let result = await this.$http.post("login.php?crud=userByAdmin", {
        id_card: id_card,
        p_word: p_word
      });
      if (result.data.user_status) {
        let user = result.data;
        user.system_lock = false;
        sessionStorage.setItem("user", JSON.stringify(user));
        if (user.user_status == "teacher") {
          this.dialog = true;
          sessionStorage.setItem("user", JSON.stringify(user));
          this.$router.push("/user");
        } else if (user.user_status == "se_director") {
          this.dialog = true;
          sessionStorage.setItem("user", JSON.stringify(user));
          this.$router.push("/user");
        } else if (user.user_status == "director") {
          this.dialog = true;
          sessionStorage.setItem("user", JSON.stringify(user));
          this.$router.push("/user");
        } else {
          sessionStorage.clear();
          this.dialog = true;
          this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
        }
      }
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          
        },
        onBeforeOpen: () => {
          Swal.showLoading();
          
        },
        onAfterClose: () => {
          Swal.hideLoading();
          
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async search_name() {
      await this.sweetAlertLoading();
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        frist_name: this.search
      });
      this.personnel_temporarys = result.data;
      Swal.close();
    },

    async search_lastname() {
      await this.sweetAlertLoading();
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        last_name: this.search
      });
      this.personnel_temporarys = result.data;
      Swal.close();
    },

    async search_idcard() {
      await this.sweetAlertLoading();
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_cards: this.search
      });
      this.personnel_temporarys = result.data;
      Swal.close();
    },

    async search_college() {
      await this.sweetAlertLoading();
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          college_code: this.college_search
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
      Swal.close();
    },

    async search_position() {
      await this.sweetAlertLoading();
      await this.search_positionQuery();
      Swal.close();
    },
    async search_positionQuery() {
      await this.sweetAlertLoading();
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          user_status: this.position_search
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
      Swal.close();
    },

    async search_rang() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          rang_name: this.rangname_search
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async search_yearretire() {
      await this.sweetAlertLoading();
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          retrire_year: this.search
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
      Swal.close();
    },

    async OnEnter() {
      await this.sweetAlertLoading();
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        frist_name: this.search
      });
      this.personnel_temporarys = result.data;
      Swal.close();
    },

    async man_powerQuery() {
      let man_power_result;
      man_power_result = await this.$http.post("jor18.php", {
        ApiKey: this.ApiKey,
        j18college_code: this.addpersonnel_temporary.college_code
      });
      this.man_powers = man_power_result.data;
    },

    async personnel_temporaryQueryAll() {
      await this.sweetAlertLoading();
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
      Swal.close();
    },

    async searchWait() {
      await this.sweetAlertLoading();
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          status_appove: "wait"
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
      Swal.close();
    },

    async personnel_temporaryAdd() {
      this.addpersonnel_temporary = {};
      this.addpersonnel_temporarydialog = true;
    },

    async personnel_temporaryCSV() {
      this.csvpersonnel_temporarydialog = true;
    },

    async personnel_temporaryOutofCSV() {
      this.csvpersonnel_temporaryOutofdialog = true;
    },

    async addpersonnel_temporarySubmit() {
      if (this.$refs.addpersonnel_temporaryform.validate()) {
        this.addpersonnel_temporary.ApiKey = this.ApiKey;
        this.addpersonnel_temporary.p_word =
          this.addpersonnel_temporary.brith_day +
          "/" +
          this.addpersonnel_temporary.brith_month +
          "/" +
          this.addpersonnel_temporary.brith_year;
        let result = await this.$http.post(
          "personnel_temporary.insert.php",
          this.addpersonnel_temporary
        );
        
        ;
        if (result.data.status == true) {
          this.personnel_temporary = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "insert";
          this.data_syslog.page_log = "personnel_temporary";
          this.data_syslog.table_log = "personnel_temporary";
          this.data_syslog.detail_log = this.personnel_temporary.id_card;
          this.data_syslog.date_times = this.date_today_log;
          this.personnel_temporaryQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "บันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
        }
        this.addpersonnel_temporarydialog = false;
      }
    },
    async personnel_temporaryEdit(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.editpersonnel_temporary.p_word = "";
      let man_power_result;
      man_power_result = await this.$http.post("jor18.php", {
        ApiKey: this.ApiKey,
        j18college_code: this.editpersonnel_temporary.college_code
      });
      this.man_powers = man_power_result.data;
      this.validateInput();
      this.editpersonnel_temporarydialog = true;
    },

    async editpersonnel_temporarySubmit() {
      if (this.$refs.editpersonnel_temporaryform.validate()) {
        this.editpersonnel_temporary.ApiKey = this.ApiKey;
        if (this.editpersonnel_temporary.p_word == "")
          delete this.editpersonnel_temporary.p_word;

        if (this.editpersonnel_temporary.rang_condition == null)
          this.editpersonnel_temporary.rang_condition = "0";


        let result = await this.$http.post(
          "personnel_temporary.update.admin.php",
          this.editpersonnel_temporary
        );
        if (result.data.status == true) {
          this.personnel_temporary = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "personnel_temporary";
          this.data_syslog.table_log = "personnel_temporary";
          this.data_syslog.detail_log = this.personnel_temporary.id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editpersonnel_temporarydialog = false;
      }
    },
    async personnel_temporaryDelete(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.deletepersonnel_temporarydialog = true;
    },

    async deletepersonnel_temporarySubmit() {
      if (this.$refs.deletepersonnel_temporaryform.validate()) {
        this.editpersonnel_temporary.ApiKey = this.ApiKey;
        let result;
        result = await this.$http.post(
          "personnel_temporary.delete.php",
          this.editpersonnel_temporary
        );

        if (result.data.status == true) {
          this.personnel_temporary = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "personnel_temporary";
          this.data_syslog.table_log = "personnel_temporary";
          this.data_syslog.detail_log = this.personnel_temporary.id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletepersonnel_temporarydialog = false;
      }
    },
    async csvpersonnel_temporarySubmit() {
      await this.sweetAlertLoading();
      let result = "";
      let uploaded = null;
      if (this.file2) {
        let formData = new FormData();
        let filename = this.time_stamp + ".personnel.csv";
        formData.append("file", this.file2);
        formData.append("filename", "../HRvecfiles/" + filename);
        formData.append("ApiKey", this.ApiKey);
        result = await this.$http.post("personnel_importcsv.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        uploaded = true;
      } else {
        uploaded = false;
      }
      if (result.data.status == true) {
        this.personnel_temporary = result.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_temporaryQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_temporaryQueryAll();
      }
      Swal.close();
      this.csvpersonnel_temporarydialog = false;
    },

    async csvpersonnel_temporaryOutofSubmit() {
      await this.sweetAlertLoading();
      let result = "";
      let uploaded = null;
      if (this.file2) {
        let formData = new FormData();
        let filename = this.time_stamp + ".personnelretried.csv";
        formData.append("file", this.file2);
        formData.append("filename", "../HRvecfiles/" + filename);
        formData.append("ApiKey", this.ApiKey);
        result = await this.$http.post(
          "personnel_importretriedcsv.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
        uploaded = true;
      } else {
        uploaded = false;
      }
      if (result.data.status == true) {
        this.personnel_temporary = result.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_temporaryQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_temporaryQueryAll();
      }
      Swal.close();
      this.csvpersonnel_temporaryOutofdialog = false;
    },

    async UpdateJ18() {
      await this.sweetAlertLoading();
      let data = {
        ApiKey: this.ApiKey
      };
      let resulta = await this.$http.post("jor18.update.php", data);
      let resultb = await this.$http.post("jor18.update.admin.php", data);
      if (resulta.data.status == true && resultb.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      Swal.close();
    },
    validateInputAdd() {
      const maxLength = 13;
      const regex = /^[0-9]\d*$/;
      let value = this.addpersonnel_temporary.id_card;
      let i, sum;
      if (
        value !== undefined &&
        value.toString().length == maxLength &&
        value.match(regex)
      ) {
        if (value.length != 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check == parseInt(value.charAt(12))) {
          this.validateInputcheck = "true";
        }
      } else {
        this.validateInputcheck = "false";
      }
    },
    async validateInput() {
      const maxLength = 13;
      const regex = /^[0-9]\d*$/;
      let value = this.editpersonnel_temporary.id_card;
      let i, sum;
      if (
        value !== undefined &&
        value.toString().length == maxLength &&
        value.match(regex)
      ) {
        if (value.length != 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check == parseInt(value.charAt(12))) {
          this.validateInputcheck = "true";
        }
      } else {
        this.validateInputcheck = "false";
      }
    }
  },
  computed: {
    time_stamp() {
      const d = new Date();
      let time = d.getTime();
      return time;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    p_word() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let password = dd + "/" + mm + "/" + yyyy;
      return password;
    },
    brith_day() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let dd = parseInt(String(today.getDate()).padStart(2, "0"));
      return dd;
    },
    brith_month() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let mm = parseInt(String(today.getMonth() + 1).padStart(2, "0"));
      return mm;
    },
    brith_year() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let yyyy = today.getFullYear() + 543;
      return yyyy;
    },

    /*  appoin_days(){
        let result 
        let appoin_day=this.editpersonnel_temporary.appoin_day
        let appoin_month=this.editpersonnel_temporary.appoin_month
        let appoin_year=this.editpersonnel_temporary.appoin_year
        result = appoin_year-543 +'-'+ appoin_month +'-'+ appoin_day
        return result
      }, */

    appoin_day() {
      let today = new Date(
        this.addpersonnel_temporary.appoin_days ||
          this.editpersonnel_temporary.appoin_days
      );
      let dd = parseInt(String(today.getDate()).padStart(2, "0"));
      return dd;
    },
    appoin_month() {
      let today = new Date(
        this.addpersonnel_temporary.appoin_days ||
          this.editpersonnel_temporary.appoin_days
      );
      let mm = parseInt(String(today.getMonth() + 1).padStart(2, "0")); //January is 0!
      return mm;
    },
    appoin_year() {
      let today = new Date(
        this.addpersonnel_temporary.appoin_days ||
          this.editpersonnel_temporary.appoin_days
      );
      let yyyy = today.getFullYear() + 543;
      return yyyy;
    },
    date_today_cal() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    retrire_year() {
      let mm = this.brith_month;
      let yy_retire = this.brith_year;
      let result;
      if (mm > 9) {
        result = yy_retire + 61;
      } else {
        result = yy_retire + 60;
      }
      return result;
    },
    cal_date_age() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },

    cal_date_age_gov() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(
        this.addpersonnel_temporary.date_app_now ||
          this.editpersonnel_temporary.date_app_now
      );

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
